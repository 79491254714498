<script setup lang="ts">
import maps from '@/data/Maps'
import {ref} from 'vue'
import {usePlacesAutocomplete} from 'vue-use-places-autocomplete'

const query = ref('')
const {suggestions} = usePlacesAutocomplete(query, {
	debounce: 50,
	apiKey: maps.googleMapsAPIKey,
	minLengthAutocomplete: 3,
	autocompletionRequest: {
		types: ['(regions)']
	}
})

defineProps({
	subscribe: {
		type: Boolean,
		default: false
	},
	athens: {
		type: Boolean,
		default: false
	},
	athensFooter: {
		type: Boolean,
		default: false
	},
	disabled: {
		type: Boolean,
		default: false
	}
})

const currentIndex = ref(-1)

const hasError = ref(false)
const hasFocus = ref(false)

const listbox = ref()
const scrollToSuggestion = (id: string) => {
	const el: HTMLElement | null = document.querySelector('.suggestion-' + id)
	if (el) {
		listbox.value.scrollTop = el.offsetTop
	}
}
const shiftPressed = ref(false)
const keydownTextInput = (e) => {
	switch (e.keyCode) {
		case 27: // Escape
			hasFocus.value = false
			e.target.blur()
			break
		case 38: // Up arrow
			e.preventDefault() // Prevent scrolling
			if (currentIndex.value > 0) {
				currentIndex.value--
			} else {
				currentIndex.value = suggestions.value.length - 1 // Go to end if at start
			}
			scrollToSuggestion(currentIndex.value)
			break
		case 40: // Down arrow
			e.preventDefault() // Prevent scrolling
			if (currentIndex.value < suggestions.value.length - 1) {
				currentIndex.value++
			} else {
				currentIndex.value = 0 // Go to start if at end
			}
			scrollToSuggestion(currentIndex.value)
			break
		case 9: // Tab
			// Handle Tab (goes to next item)
			if (currentIndex.value < suggestions.value.length - 1) {
				currentIndex.value++
			} else {
				currentIndex.value = 0 // Go to start if at end
			}
			scrollToSuggestion(currentIndex.value)
			break
	}
}

const keydown = (e) => {
	// Check if shift key is pressed
	if (e.keyCode === 16) {
		shiftPressed.value = true
	}
	switch (e.keyCode) {
		case 38: // Up arrow
			e.preventDefault() // Prevent scrolling
			if (currentIndex.value > 0) {
				currentIndex.value--
			} else {
				currentIndex.value = suggestions.value.length - 1 // Go to end if at start
			}
			scrollToSuggestion(currentIndex.value)
			break
		case 40: // Down arrow
			e.preventDefault() // Prevent scrolling
			if (currentIndex.value < suggestions.value.length - 1) {
				currentIndex.value++
			} else {
				currentIndex.value = 0 // Go to start if at end
			}
			scrollToSuggestion(currentIndex.value)
			break
		case 13: // Enter
			itemSelected(currentIndex.value)
			break
		case 27: // Escape
			hasFocus.value = false
			e.target.blur()
			break
		case 9: // Tab
			e.preventDefault()
			if (shiftPressed.value) {
				// Handle Shift+Tab (goes to previous item)
				if (currentIndex.value > 0) {
					currentIndex.value--
				} else {
					currentIndex.value = suggestions.value.length - 1 // Go to end if at start
				}
			} else {
				// Handle Tab (goes to next item)
				if (currentIndex.value < suggestions.value.length - 1) {
					currentIndex.value++
				} else {
					currentIndex.value = 0 // Go to start if at end
				}
			}
			scrollToSuggestion(currentIndex.value)
			break
	}
}

const keyup = (e) => {
	if (e.keyCode === 16) {
		shiftPressed.value = false
	}
}

const suggestionSelected = ref(false)

const emit = defineEmits(['selected'])
const itemSelected = (index) => {
	// Populate input with selected item
	query.value = suggestions.value[index].description
	suggestionSelected.value = true
	hasFocus.value = false

	emit('selected', query.value)
}

let timeout: number
const setHasFocus = () => {
	clearTimeout(timeout)
	hasFocus.value = true
}

const clearHasFocus = () => {
	timeout = setTimeout(() => {
		hasFocus.value = false
		currentIndex.value = -1

		// Validate error
		hasError.value = !suggestionSelected.value && query.value.length > 0
		if (hasError.value) {
			emit('selected', false)
		}
	}, 100)
}

const validate = () => {
	hasError.value = !suggestionSelected.value && query.value.length > 0
}
</script>

<template>
	<div
		:class="{
			'city-field--subscribe': subscribe,
			'city-field--athens': athens,
			'city-field--athens-footer': athensFooter
		}"
		class="city-field"
		@focusin="setHasFocus">
		<input
			type="text"
			required
			v-model="query"
			:disabled="disabled"
			:class="{error: hasError}"
			placeholder="Current city of residence"
			autocomplete="off"
			name="city_of_residence"
			@input="suggestionSelected = false"
			@keydown="keydownTextInput"
			@focusout="clearHasFocus"
			@change="validate" />
		<ul
			role="listbox"
			ref="listbox"
			class="absolute z-50 w-full"
			:class="{hidden: !hasFocus || suggestions.length === 0}"
			@keydown="keydown"
			@keyup="keyup"
			@focusout="clearHasFocus">
			<li
				tabindex="0"
				:key="item.place_id"
				v-for="(item, index) in suggestions"
				:class="[{active: index === currentIndex}, `suggestion-${index}`]"
				role="option">
				<button @click.prevent="itemSelected(index)" class="w-full text-left">
					{{ item.description }}
				</button>
			</li>
		</ul>
	</div>
</template>

<style scoped lang="scss">
.city-field {
	@apply relative;

	input {
		@apply w-full border border-solid;

		&::placeholder {
			color: currentColor;
		}
	}

	&--athens {
		@apply bg-white text-black;

		input {
			@apply border-0 border-b;

			&.error {
				@apply border-0 border-b border-[red];
			}
		}

		ul {
			@apply border-b;
		}

		li {
			&:last-child {
				@apply border-b-0;
			}
		}
	}

	&--athens-footer {
		input {
			@apply border-0 border-b;

			&.error {
				@apply border-0 border-b border-[red];
			}
		}

		ul {
			@apply max-h-[120px] bg-terracotta text-black;
		}

		li {
			&:last-child {
				@apply border-b-0;
			}
		}
	}

	&--subscribe {
		@apply bg-black;

		input {
			@apply border-0 border-b p-2 px-2;
		}

		ul {
			@apply border-white text-caption-m md:text-caption;
		}

		li {
			@apply border-white;
			button {
				&:hover,
				&:focus-visible {
					@apply bg-white text-black;
				}
			}
		}

		li.active button {
			@apply bg-white text-black;
		}
	}
}

ul {
	@apply overflow-y-scroll border-b border-solid bg-[inherit];
}

li {
	@apply border-x border-b border-solid border-black;

	&.active {
		button {
			@apply bg-black text-white;
		}
	}

	&:last-child {
		@apply border-b-0;
	}
}

input {
	&.error {
		@apply border border-[red];
	}
}

button {
	@apply px-2 py-3;

	&:hover,
	&:focus-visible {
		@apply bg-black text-white;
	}
}
</style>
